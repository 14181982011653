import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import { AppLoader } from './AppLoader';
const AuthProvider = React.lazy(() => import('@wyden/services/keycloak/auth/AuthProvider'));
import { ThemeProvider } from '@ui/ThemeProvider';
import { getConfig } from './services/REST/rest';
import { MainAppSkeleton } from './features/MainAppSkeleton';

// Uncomment if needed for development
// const { worker } = await import('../../mocks/development');
// worker.start();
if (process.env.NODE_ENV === 'development') {
  const originalErrorMessage = console.error;

  console.error = function (message) {
    if (message?.includes && message.includes('*')) return;

    originalErrorMessage(message);
  };
}

// Uncomment if needed for mocking websocket
// also follow the instructions in the README.md
// import { mockWebsocket } from '@wyden/mock.websocket';
// mockWebsocket();

// Uncomment if needed for development
// const { worker } = await import('../../mocks/development');
getConfig()
  .then((data) => {
    if (data) {
      window.config = data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ReactDOM.createRoot(document.getElementById('root')!).render(
        // Strict mode causes keycloak infinite loop
        // <React.StrictMode>
        <ThemeProvider>
          <Suspense fallback={<MainAppSkeleton />}>
            <AuthProvider>
              <AppLoader />
            </AuthProvider>
          </Suspense>
        </ThemeProvider>,
        // </React.StrictMode>
      );
    }
  })
  .catch((err) => console.log(err));
